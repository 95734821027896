import AppRouter from "./AppRouter";
import NavBar from "./components/Navigation/NavBar";
import Footer from "./components/Footer";
import ContactModal from "./components/Contact/ContactModal";
import React, {useState} from "react";
import Toast from "./components/Toast";
import Drawer from "./components/Navigation/Drawer";
import CarouselDetailModal from "./components/Carousel/CarouselDetailModal";

export const API_ADDRESS = "https://api.foti-immo.ch/api";

export const AppContext = React.createContext({
    contactModalIsOpen: false,
    setContactModalIsOpen: (contactModalIsOpen) => {
        this.contactModalIsOpen = contactModalIsOpen;
    },
    contactModalPropertyRef: null,
    setContactModalPropertyRef: (contactModalPropertyRef) => {
        this.contactModalPropertyRef = contactModalPropertyRef;
    },
    toastIsOpen: false,
    setToastIsOpen: (toastIsOpen) => {
        this.toastIsOpen = toastIsOpen;
    },
    toastMessage: "",
    setToastMessage: (toastMessage) => {
        this.toastMessage = toastMessage;
    },
    toastType: "info",
    setToastType: (toastType) => {
        this.toastType = toastType;
    },
    drawerIsOpen: false,
    setDrawerIsOpen: (drawerIsOpen) => {
        this.drawerIsOpen = drawerIsOpen;
    },
    carouselDetailModalIsOpen: false,
    setCarouselDetailModalIsOpen: (carouselDetailModalIsOpen) => {
        this.carouselDetailModalIsOpen = carouselDetailModalIsOpen;
    },
    carouselDetailModalAttachments: [],
    setCarouselDetailModalAttachments: (carouselDetailModalAttachments) => {
        this.carouselDetailModalAttachments = carouselDetailModalAttachments;
    },
    carouselDetailModalId: 0,
    setCarouselDetailModalId: (carouselDetailModalId) => {
        this.carouselDetailModalId = carouselDetailModalId;
    },
});

function App() {
    const [contactModalIsOpen, setContactModalIsOpen] = useState(false);
    const [contactModalPropertyRef, setContactModalPropertyRef] = useState(null);
    const [carouselDetailModalIsOpen, setCarouselDetailModalIsOpen] = useState(null);
    const [carouselDetailModalAttachments, setCarouselDetailModalAttachments] = useState([]);
    const [carouselDetailModalId, setCarouselDetailModalId] = useState(0);

    const [toastIsOpen, setToastIsOpen] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [toastType, setToastType] = useState("info");

    const [drawerIsOpen, setDrawerIsOpen] = useState(false);

    const value = {
        contactModalIsOpen,
        setContactModalIsOpen,
        contactModalPropertyRef,
        setContactModalPropertyRef,
        toastIsOpen,
        setToastIsOpen,
        toastMessage,
        setToastMessage,
        toastType,
        setToastType,
        drawerIsOpen,
        setDrawerIsOpen,
        carouselDetailModalIsOpen,
        setCarouselDetailModalIsOpen,
        carouselDetailModalAttachments,
        setCarouselDetailModalAttachments,
        carouselDetailModalId,
        setCarouselDetailModalId
    };

    function handleClick(){
        if (drawerIsOpen){
            setDrawerIsOpen(false);
        }

        if (contactModalIsOpen){
            setContactModalIsOpen(false);
        }

        if (carouselDetailModalIsOpen){
            setCarouselDetailModalIsOpen(false);
        }
    }
    if (window.location.href.includes("vitrine")){
        return <AppRouter />
    }

    return (
        <div>
            <AppContext.Provider value={value}>
                <ContactModal/>
                <CarouselDetailModal/>
                <Drawer/>
                <Toast/>
                <div id="mainContent"
                     className={drawerIsOpen || contactModalIsOpen || carouselDetailModalIsOpen ? "blur-md" : ""}
                     onClick={handleClick}
                >
                    <div className={drawerIsOpen || contactModalIsOpen || carouselDetailModalIsOpen ? "pointer-events-none" : ""}>
                        <NavBar/>
                        <AppRouter/>
                        <Footer/>
                    </div>
                </div>
            </AppContext.Provider>
        </div>
    );
}

export default App;
