import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from "./pages/Home";
import Buy from "./pages/Buy";
import Rent from "./pages/Rent";
import Sell from "./pages/Sell";
import React from "react";
import PromotionDetails from "./components/Promotion/PromotionDetails";
import Project from "./pages/Project";
import Sold from "./pages/Sold";
import PropertyDetails from "./components/Property/PropertyDetails";
import Share from "./pages/Share";
import Promotions from "./pages/Promotions";
import SatisfactionForm from "./components/Contact/SatisfactionForm";
import Team from "./pages/Team";
import Screen from "./pages/Screen";

function AppRouter() {

    if(window.location.pathname === '/fr' || window.location.pathname.includes('/fr/')){
        window.location.pathname = '/';
    }


    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/acheter" element={<Buy/>}/>
                <Route path="/louer" element={<Rent/>}/>
                <Route path="/vendre" element={<Sell/>}/>
                <Route path="/vendus" element={<Sold/>}/>
                <Route path="/propriete" element={<PropertyDetails/>}/>
                <Route path="/contact/satisfaction" element={<SatisfactionForm/>}/>
                <Route path="/partage/:ref" element={<Share/>}/>
                <Route path="/promotions" element={<Promotions/>}/>
                <Route path="/equipe" element={<Team/>}/>
                <Route path="/promotion/:ref" element={<PromotionDetails/>}/>
                <Route path="/promotion/:ref/projet" element={<Project/>}/>
                <Route path="/promotion/:ref/propriete" element={<PropertyDetails/>}/>
                <Route path="/vitrine/:id" element={<Screen/>}/>
            </Routes>
        </BrowserRouter>
    );
}

export default AppRouter;
