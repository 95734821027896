import {useEffect, useState} from "react";
import Home from "../components/Screens/Home";
import Home2 from "../components/Screens/Home2";
import Properties from "../components/Screens/Properties";
import {API_ADDRESS} from "../App";
import Services from "../components/Screens/Services";
import Team from "../components/Screens/Team";

export const TIME_ON_SCREEN = 7;

function Screen() {
    const screenId = window.location.href.split("/")[window.location.href.split("/").length - 1];

    const [rotationId, setRotationId] = useState(0);
    const [properties, setProperties] = useState([]);

    let rotation;

    rotation = [
        {name: "home", component: Home, time: TIME_ON_SCREEN},
        {name: "home2", component: Home2, time: TIME_ON_SCREEN},
        {name: "properties", component: Properties, time: TIME_ON_SCREEN * properties.length},
        {name: "services", component: Services, time: TIME_ON_SCREEN},
        {name: "team", component: Team, time: TIME_ON_SCREEN},
    ];

    useEffect(() => {
        const fetchData = async () => {
            fetch(API_ADDRESS + '/properties/screen/' + screenId)
                .then(res => res.json())
                .then(json => {
                    setProperties(json.data);
                });
        };

        async function rotate() {
            if (rotationId === 0){
                fetchData();
            }
            setRotationId(rotationId === rotation.length - 1 ? 0 : rotationId + 1)
        }

        const rotateTimer = setInterval(() => {
            rotate();
        }, rotation[rotationId].time * 1000);
        return () => {
            clearInterval(rotateTimer);
        };
    }, [rotation, rotationId, screenId]);

    if(rotation[rotationId].name === "properties"){
        return <Properties properties={properties} />
    }
    return rotation[rotationId].component();
}

export default Screen;
