import {useEffect, useState} from "react";
import {TIME_ON_SCREEN} from "../../pages/Screen";
import Property from "./Property";


function Properties(props) {
    const [propertyId, setPropertyId] = useState(0);
    let properties = props.properties;

    useEffect(() => {
        async function rotate() {
            setPropertyId(propertyId + 1);
        }

        const rotateTimer = setInterval(() => {
            rotate();
        }, TIME_ON_SCREEN * 1000);
        return () => {
            clearInterval(rotateTimer);
        };
    }, [propertyId]);


    if (properties[propertyId] !== undefined) {
        return (
            <>
                <Property property={properties[propertyId]} />
            </>
        );
    }
}

export default Properties;
