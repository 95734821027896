import React from "react";
import specifications from "../../data/specifications.json";
import '../../media/styles/styles.css';
import status from "../../data/status.json";
import Carousel from "../Carousel/Carousel";
import ContactModalButton from "../Contact/ContactModalButton";
import ContactCard from "../Contact/ContactCard";

function PropertyDetailsDesktop(props) {
    const property = props.property;
    const promotion = props.promotion;

    function toHumanReadable(value){
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
    }

    function specValueToHumanReadable(spec) {
        if(spec.name === "floor" && spec.value === 0){
            return "Rez";
        }

        if(specifications[spec.name]?.unit === "CHF" || specifications[spec.name]?.unit === "m" || specifications[spec.name]?.unit === "m2" || specifications[spec.name]?.unit === "m3"){
            return toHumanReadable(spec.value);
        }

        return spec.value;
    }

    return (
        <>
            <div className="relative container mx-auto w-full md:w-5/6">
                <Carousel images={property.attachments} details={true}/>
                <div className="absolute -top-7 right-20 z-40">
                    <ContactModalButton propertyRef={property.ref} />
                </div>
                {property.video ?
                    <div className="absolute right-20 -bottom-4 z-40">
                        <a href={property.video.url} rel="noreferrer" target="_blank">
                            <div className="text-lg uppercase bg-red-600 text-white font-bold py-1 px-4 w-fit">
                                Vidéo du bien
                            </div>
                        </a>
                    </div>
                :""}
                <div className="absolute w-full -bottom-16 ">
                    <div className="container mx-auto w-1/2 relative">
                        <div className="flex justify-center group bg-primary w-full flex-col text-white px-6 py-3 text-center shadow-lg shadow-gray-600">
                            <div>
                                <p className="text-3xl font-bold">
                                    {property.name}
                                </p>
                                <p className="text-lg">{property.subtitle}</p>
                            </div>
                        </div>
                        {property.status !== "active" ? (
                                <div className="absolute flex justify-center container mx-auto mb-2 -top-6">
                                    <div className="text-lg uppercase bg-red-600 text-white font-bold py-1 px-4 w-fit">
                                        {status[property.status]}
                                    </div>
                                </div>
                            )
                            :
                            (<div></div>)}
                    </div>
                </div>

                <div className="absolute -bottom-36 right-0 flex flex-col gap-y-2 ">
                    <div className="flex justify-start items-center">
                        <svg className="w-6 h-6 text-primary mr-2 ml-0.5" aria-hidden="true"
                             xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 18">
                            <path
                                d="M15.045.007 9.31 0a1.965 1.965 0 0 0-1.4.585L.58 7.979a2 2 0 0 0 0 2.805l6.573 6.631a1.956 1.956 0 0 0 1.4.585 1.965 1.965 0 0 0 1.4-.585l7.409-7.477A2 2 0 0 0 18 8.479v-5.5A2.972 2.972 0 0 0 15.045.007Zm-2.452 6.438a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z"/>
                        </svg>
                        <p className="text-black text-xl">
                            {property.price === 0 ?
                            "Prix sur demande"
                                :
                            <>
                                CHF {toHumanReadable(property.price)}.–{property.type === "rent" ? "/mois" : ""}
                            </>
                            }
                        </p>
                    </div>
                    <div className="flex justify-start items-center">
                        <svg className="w-6 h-6 text-primary mr-2 ml-0.5" aria-hidden="true"
                             xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                            <path
                                d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                        </svg>
                        <p className="text-black text-xl">
                            Réf {property.ref}
                        </p>
                    </div>
                    {promotion ?
                        <a href={"/promotion/" + property.ref.split("-")[0]} target="_blank" className="flex justify-start group items-center" rel="noreferrer">
                            <svg className="w-7 h-7 text-primary mr-2 group-hover:text-primary-light" aria-hidden="true"
                                 xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 18">
                                <path
                                    d="M17 16h-1V2a1 1 0 1 0 0-2H2a1 1 0 0 0 0 2v14H1a1 1 0 0 0 0 2h16a1 1 0 0 0 0-2ZM5 4a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V4Zm0 5V8a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1Zm6 7H7v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3Zm2-7a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1Zm0-4a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1Z"/>
                            </svg>
                            <p className="text-black text-xl text-right group-hover:text-primary-light">
                                Voir promotion
                            </p>
                            <svg className="w-4 h-4 text-primary ml-2 group-hover:text-primary-light" aria-hidden="true"
                                 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                      strokeWidth="2"
                                      d="M15 11v4.833A1.166 1.166 0 0 1 13.833 17H2.167A1.167 1.167 0 0 1 1 15.833V4.167A1.166 1.166 0 0 1 2.167 3h4.618m4.447-2H17v5.768M9.111 8.889l7.778-7.778"/>
                            </svg>
                        </a>
                    :""}
                    <a href={"https://www.google.com/maps/place/"+property.locality + ", " + property.region + ", Suisse"} target="_blank" className="flex justify-start group items-center" rel="noreferrer">
                        <svg className="w-7 h-7 text-primary mr-2 group-hover:text-primary-light" aria-hidden="true"
                             xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 20">
                            <path
                                d="M8 0a7.992 7.992 0 0 0-6.583 12.535 1 1 0 0 0 .12.183l.12.146c.112.145.227.285.326.4l5.245 6.374a1 1 0 0 0 1.545-.003l5.092-6.205c.206-.222.4-.455.578-.7l.127-.155a.934.934 0 0 0 .122-.192A8.001 8.001 0 0 0 8 0Zm0 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6Z"/>
                        </svg>
                        <p className="text-black text-xl text-right group-hover:text-primary-light">
                            {property.postalCode} {property.locality}, {property.region}
                        </p>
                        <svg className="w-4 h-4 text-primary ml-2 group-hover:text-primary-light" aria-hidden="true"
                             xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M15 11v4.833A1.166 1.166 0 0 1 13.833 17H2.167A1.167 1.167 0 0 1 1 15.833V4.167A1.166 1.166 0 0 1 2.167 3h4.618m4.447-2H17v5.768M9.111 8.889l7.778-7.778"/>
                        </svg>
                    </a>
                </div>
            </div>
            <div className="w-4/6 text-lg pt-4 md:pt-24">
                <div className="flex">
                    <div className="py-4 px-4 md:px-0 w-5/6">
                        <h2 className="text-3xl font-bold">Présentation</h2>
                        <div className="py-2 pr-4 styled-ul" dangerouslySetInnerHTML={{__html: property.description}}>
                        </div>
                        <h2 className="text-3xl font-bold mt-6">Données techniques</h2>
                        {property.importantSpecifications.map((spec, index) => (
                            <div key={index} className="py-1" >
                                {specifications[spec.name]?.name}: <strong>{specValueToHumanReadable(spec)} {specifications[spec.name]?.unit ? specifications[spec.name].unit : ""}</strong>
                            </div>
                        ))}
                        {property.hasOwnProperty("features") && property.features["has-garage"] ?
                            <div className="py-1" >
                                Garage: <strong>oui</strong>
                            </div>
                            :""}
                        {property.hasOwnProperty("features") && property.features["has-parking"] ?
                            <div className="py-1" >
                                Parking: <strong>oui</strong>
                            </div>
                            :""}
                        {property.specifications.map((spec, index) => (
                            <div key={index} className="py-1" >
                                {specifications[spec.name]?.name}: <strong>{specValueToHumanReadable(spec)} {specifications[spec.name]?.unit ? specifications[spec.name].unit : ""}</strong>
                            </div>
                        ))}
                    </div>
                    <div className="mt-16 flex justify-end w-2/6">
                        <ContactCard
                            agent={{
                                givenName: property.contact.agentGivenName,
                                familyName: property.contact.agentFamilyName,
                                mobile: property.contact.agentMobile,
                                phone: property.contact.agentPhone
                            }}
                            company={{
                                name: property.contact.company,
                                locality: property.contact.companyLocality,
                                phone: property.contact.companyPhone,
                                postalCode:property.contact.companyPostalCode,
                                street:property.contact.companyStreet,
                                streetNumber:property.contact.companyStreetNumber,
                            }}
                            property_ref={property.ref}
                            title={property.name}
                            description={property.subtitle}
                            image={property.attachments[0].url}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default PropertyDetailsDesktop;
