import React from "react";
import {ReactComponent as BullsEyeSvg} from "../../media/images/screens/bullseye.svg";
import team from "../../data/team.json";
import "./Team.css";

function Team() {
    return (
        <div className="h-screen bg-primary overflow-hidden">
            <div className="text-white text-5xl w-full pt-24 px-24 flex justify-between h-1/5">
                <p className="text-5xl">Votre équipe pour un succès assuré !</p>
                <BullsEyeSvg className="w-28" />
            </div>
            <div className="flex justify-center gap-x-24 h-2/5 pt-8">
                {team.people.filter((person) => person.role !== "Responsable administrative").map((person, index) => (
                    <div key={index} className="flex flex-col">
                        <img
                            className="h-96 object-contain object-top"
                            src={person.image_url.startsWith("http") ? person.image_url : process.env.PUBLIC_URL + person.image_url}
                            alt=""/>
                        <div className="text-white text-center">
                            <p className="text-2xl font-bold">
                                {person.first_name + " " + person.last_name}
                            </p>
                            <p className="text-xl">{person.role}</p>
                        </div>
                    </div>
                ))}
            </div>
            <div className="flex h-2/5 pt-8">
                <div className="w-1/5 flex align-bottom justify-start">
                    <img alt="" className="object-contain w-10/12"
                         src={require("../../media/images/icons/logo_white.png")}/>
                </div>
                <div className="flex align-bottom justify-center w-3/5 text-white text-center pt-10 gap-y-8 pb-10">
                    <div className="flex flex-col justify-between w-4/5">
                        <p className="font-bold text-4xl">POUR VOUS</p>
                        <p className="text-6xl hand-written-font">...plus de 20 ans d'expérience à votre service...</p>
                        <p className="font-light text-4xl">DEPUIS 2001</p>
                    </div>
                </div>
                <div className="w-1/5 flex align-bottom justify-end">
                    <img alt="" className="object-contain w-10/12"
                         src={require("../../media/images/icons/logo_white.png")}/>
                </div>
            </div>
        </div>
    );
}

export default Team;