import React from "react";

function Services() {
    return (
        <div className="h-screen bg-primary overflow-hidden">
            <div className="text-white text-5xl w-full pt-24 px-24 flex justify-between h-1/4">
                <p><span className="border-b-8 border-white text-7xl font-bold">Nos services !</span>
                </p>
                <img alt="" className="object-contain w-48" src={require("../../media/images/icons/logo_white.png")}/>
            </div>
            <div className="flex h-3/4 pt-8">
                <div className="w-1/3">
                    <div className="h-1/2">
                        <img className="h-full w-full object-fill" alt=""
                             src={require("../../media/images/screens/services_1.webp")}/>
                    </div>
                    <div className="h-1/2 p-6 text-white">
                        <p className="mb-12"><span
                            className="border-b-4 border-white text-5xl font-bold">Vente de biens</span>
                        </p>
                        <p className="text-3xl">Appartements, villas, terrains, immeubles etc.</p>
                    </div>
                </div>
                <div className="w-1/3">
                    <div className="h-1/2">
                        <img className="h-full w-full object-fill" alt=""
                             src={require("../../media/images/screens/services_2.webp")}/>
                    </div>
                    <div className="h-1/2 p-6 text-white">
                        <p className="mb-12"><span className="border-b-4 border-white text-5xl font-bold">Estimation de biens</span>
                        </p>
                        <p className="text-3xl">Gratuites en vue d'une mise en vente ou par écrit pour les affaires
                            financières ou juridiques.</p>
                    </div>
                </div>
                <div className="w-1/3">
                    <div className="h-1/2">
                        <img className="h-full w-full object-fill" alt=""
                             src={require("../../media/images/screens/services_3.webp")}/>
                    </div>
                    <div className="h-1/2 p-6 text-white">
                        <p className="mb-12"><span className="border-b-4 border-white text-5xl font-bold">Financement de biens</span>
                        </p>
                        <p className="text-3xl">Étude de dossier et solutions sur mesure.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Services;