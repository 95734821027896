import React from "react";

function Home() {
    return (
        <div className="h-screen bg-primary overflow-hidden">
            <div className="w-full h-2/5">
                <img alt="" className="h-full w-full"
                     src={require("../../media/images/screens/home.webp")}/>
            </div>
            <div className="flex flex-col justify-center h-3/5">
                <div className="flex justify-between">
                    <div className="flex align-middle p-5 justify-center w-1/4">
                        <img alt="" className="object-contain"
                             src={require("../../media/images/icons/logo_white.png")}/>
                    </div>
                    <div className="text-white text-5xl text-right pr-24 w-full">
                        <p className="-mt-10 mb-6"><span className="border-b-8 border-white text-8xl font-bold text-nowrap">Au sujet de  FOTI Immobilier</span></p>
                        <p>Nous faisons de l'immobilier depuis 2001, et sommes</p>
                        <p>appréciés par nos clients pour nos résultats</p>
                        <p>et notre professionnalisme.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;