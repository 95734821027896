import React from "react";
import PropertyFlyer from "../Property/PropertyFlyer";


function Property(props) {
    let property = props.property;

    return (
        <div className="flex h-screen overflow-hidden bg-gradient-to-r from-primary from-25% to-75% to-white">
            <div className="grid grid-cols-1 w-1/2 px-10 py-20 gap-y-16">
                <p><span className="border-b-8 border-white text-white text-6xl font-bold">Nous vous proposons :</span>
                </p>
                <div>
                    <img alt="" className="object-contain w-2/5"
                         src={require("../../media/images/icons/logo_white.png")}/>
                </div>
                <div className="flex justify-end pr-[20%]">
                    <img alt="" className="object-contain w-2/5"
                         src={require("../../media/images/screens/qr_code.png")}/>
                </div>
                <p className="text-center"><span className="text-white text-4xl">20 ans d'expérience dans l'immobilier !</span></p>
            </div>
            <div className="w-1/2">
                <PropertyFlyer property={property} />
            </div>
        </div>
);
}

export default Property;
