import React from "react";

function PropertyFlyerDetail(props) {
    const detailName = props.name;
    const detailValue = props.value;
    const detailImgSrc = props.imgSrc;

    return (
        <div className="flex h-fit">
            <div className="flex">
                <img alt="" className="w-7 h-7 self-center object-contain"
                     src={detailImgSrc}/>
            </div>
            <div className="ml-2">
                <p className="font-semibold">{detailName}</p>
                <p className="font-bold">{detailValue}</p>
            </div>
        </div>
    );
}

export default PropertyFlyerDetail;
