import React from "react";
import countries from "../../data/countries.json";
import categories from "../../data/categories.json";
import features from "../../data/features.json";
import "./Flyer.css";
import PropertyFlyerDetail from "./PropertyFlyerDetail";

function PropertyFlyer(props) {
    const property = props.property;

    let featuresArray = [];
    for (let feature in property.features) {
        if(features[feature] !== undefined){
            featuresArray.push(features[feature]);
        }
    }
    featuresArray.sort();

    function toHumanReadable(value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
    }

    function getCountryName(countryKey) {
        return countries.find(country => {
            return country.code === countryKey
        }).name;
    }

    function getSpecByName(specifications, name) {
        return specifications.find(specification => {
            return specification.name === name
        });
    }

    function nRoomsToHumanReadable(nRooms) {
        if (!Number.isInteger(nRooms)) {
            return Math.floor(nRooms).toString() + "½";
        }

        return nRooms;
    }

    return (
        <div className="flex flex-col justify-between bg-white px-12 py-6 flyer-font h-full">
            <div>
                <p className="text-primary text-xl mb-2">{property.postalCode} {property.locality}, {getCountryName(property.country)}</p>
                <p className="font-bold text-3xl mb-2">{"buildings" in property ? property.title : property.name}</p>
                <p className="text-md mb-6">{property.subtitle}</p>
            </div>
            <div className="flex justify-center max-h-[30rem] mb-6">
                <img className="w-full object-contain" alt="" src={property.attachments[0].url}/>
            </div>
            <div className="flex text-lg font-light">
                <div className="grid grid-cols-2 gap-y-4 w-2/3 h-full">
                    <PropertyFlyerDetail name="Type de bien" value={property.type === "buy" ? "Vente" : "Location"}
                                         imgSrc={require("../../media/images/screens/type.png")} />
                    <PropertyFlyerDetail name="Catégorie d'objet" value={"buildings" in property ? "Promotion" : categories[property.categories[0].name]}
                                         imgSrc={require("../../media/images/screens/category.png")} />
                    <PropertyFlyerDetail name={"Prix de " + (property.type === "buy" ? "vente" : "location")}
                                         value={property.price === 0 ?
                                             "Prix sur demande" : ("CHF " + toHumanReadable(property.price) + ".–" + (property.type === "rent" ? "/mois" : ""))}
                                         imgSrc={require("../../media/images/screens/price.png")} />
                    {/*<PropertyFlyerDetail name={"Disponibilité"}
                                         value={"Sur demande"}
                                         imgSrc={require("../../media/images/screens/availability.png")} />*/}
                    {getSpecByName(property.importantSpecifications, "area_bwf") !== undefined ?
                        <PropertyFlyerDetail name={"Surface habitable brute"}
                                             value={getSpecByName(property.importantSpecifications, "area_bwf").value + " m²"}
                                             imgSrc={require("../../media/images/screens/livable_area.png")} />
                        : ""}
                    {getSpecByName(property.importantSpecifications, "number_of_rooms") !== undefined ?
                        <PropertyFlyerDetail name={"Pièces"}
                                             value={nRoomsToHumanReadable(getSpecByName(property.importantSpecifications, "number_of_rooms").value)}
                                             imgSrc={require("../../media/images/screens/number_of_rooms.png")} />
                        : ""}
                    {getSpecByName(property.importantSpecifications, "year_built") !== undefined ?
                        <PropertyFlyerDetail name={"Année de construction"}
                                             value={nRoomsToHumanReadable(getSpecByName(property.importantSpecifications, "year_built").value)}
                                             imgSrc={require("../../media/images/screens/year_built.png")} />
                        : ""}
                    <PropertyFlyerDetail name={"No de référence"}
                                         value={property.ref}
                                         imgSrc={require("../../media/images/screens/reference_number.png")} />
                </div>
                <div className="w-1/3 h-full">
                    {featuresArray.map((feature, index) => (
                        <div key={index} className="flex align-middle">
                            <svg className="w-7 h-7" fill="none"
                                 viewBox="0 0 24 24">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                      strokeWidth="2" d="M5 11.917 9.724 16.5 19 7.5"/>
                            </svg>
                            <p className="text-md font-semibold">{feature}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default PropertyFlyer;
