import React from "react";

function Home2() {
    return (
        <div className="h-screen bg-primary overflow-hidden">
            <div className="flex flex-col justify-center h-3/5">
                <div className="flex justify-between">
                    <div className="flex align-middle p-5 justify-center w-1/4">
                        <img alt="" className="object-contain" src={require("../../media/images/icons/logo_white.png")}/>
                    </div>
                    <div className="text-white text-5xl text-right pr-24 w-full">
                        <p className="-mt-10 mb-6"><span className="border-b-8 border-white text-8xl font-bold">Ce qui nous distingue</span></p>
                        <p>Notre souhait est d'offrir la possibilité à nos clients</p>
                        <p>de trouver le bien le plus approprié</p>
                        <p>à leurs nécessités...</p>
                    </div>
                </div>
            </div>
            <div className="w-full h-2/5">
                <img alt="" className="h-full w-full" src={require("../../media/images/screens/home2.jpeg")}/>
            </div>
        </div>
    );
}

export default Home2;